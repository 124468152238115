<template>
  <div class="battery">
    <div class="title">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="通讯板编号">
          <el-input
            v-model="searchForm.communicationBoardNo"
            clearable
            placeholder="请输入通讯板编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input
            v-model="searchForm.phone"
            clearable
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="子订单号">
          <el-input
            v-model="searchForm.subOrderNo"
            clearable
            placeholder="请输入子订单号"
          ></el-input>
        </el-form-item>
        <el-form-item label="主订单号">
          <el-input
            v-model="searchForm.orderNo"
            clearable
            placeholder="请输入主订单号"
          ></el-input>
        </el-form-item>
        <el-form-item label="选择组织">
          <AgentTree
            :props="props"
            clearable
            style="width: 300px"
            v-model="searchForm.unitNo"
          >
          </AgentTree>
        </el-form-item>
        <el-form-item label="过期时间">
          <el-select
            ref="searchFormExpireTimeType"
            v-model="searchForm.expireTimeType"
            clearable
            placeholder="请选择过期时间"
          >
            <el-option
              v-for="opt in EXPIRE_TYPE"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="放电开关">
          <el-select
            ref="searchFormDischargeMosStatus"
            v-model="searchForm.dischargeMosStatus"
            clearable
            placeholder="请选择放电开关"
          >
            <el-option :value="1" label="开启"></el-option>
            <el-option :value="2" label="关闭"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付方式">
          <el-select
            ref="searchFormPayType"
            v-model="searchForm.payType"
            clearable
            placeholder="请选择支付方式"
          >
            <el-option
              v-for="opt in PAY_TYPE"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租赁时间">
          <el-date-picker
            v-model="time"
            clearable
            value-format="timestamp"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            align="right"

          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单类型">
          <el-select
            ref="searchFormOrderType"
            v-model="searchForm.orderType"
            clearable
            placeholder="请选择订单类型"
          >
            <el-option
              v-for="opt in ORDER_TYPE"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select
            ref="searchFormOrderStatus"
            v-model="searchForm.orderStatus"
            clearable
            placeholder="请选择订单状态"
          >
            <el-option
              v-for="opt in ORDER_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
            v-model="createTimeArr"
            clearable
            value-format="timestamp"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            align="right"

          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <!-- TO DO  -->
          <el-button type="primary" @click="exportBtn">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
        :data="list"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="30"></el-table-column>
        <el-table-column prop="deviceNo" min-width="190" label="设备编号" fixed>
          <template slot="header">
            <p>订单号</p>
          </template>
          <template slot-scope="{ row }">
            <p><span class="c9">子:</span> {{ row.subOrderNo }}</p>
            <p><span class="c9">主:</span> {{ row.orderNo }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="communicationBoardNo"
          label="设备通讯板编号"
          min-width="120"
        >
        </el-table-column>
        <el-table-column prop="agentName" label="代理商" width="100">
        </el-table-column>
        <el-table-column prop="storeName" width="70" label="门店">
        </el-table-column>
        <el-table-column prop="orderType" label="订单类型" width="50" >
          <template slot-scope="{ row }" >
            <span :class="[{'blue':row.orderType == 3},{'orange': row.orderType == 2},]">
              {{ $changeObj(ORDER_TYPE)[row.orderType] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="payType" label="支付方式" width="70">
          <template slot-scope="{ row }">
            <span :class="['bkorange','orange']">
              {{ $changeObj(PAY_TYPE)[row.payType] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="eleSpecification" label="商品规格" width="100">
          <!-- 为电池型号 -->
          <template slot-scope="{row}">
            <p>{{row.eleSpecification}}</p>
            <p>{{row.carSpecification}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="订单状态" width="100">
          <template slot-scope="{ row }">
            {{ $changeObj(ORDER_STATUS)[row.status] }}
          </template>
        </el-table-column>
        <el-table-column prop="confirm" label="确认状态" width="70">
          <template slot-scope="{ row }">
            {{ $changeObj(CONFIRM_TYPE)[row.confirm] }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="110">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="70">
        </el-table-column>
        <el-table-column prop="totalCashPledge" label="押金" width="50">
        </el-table-column>
        <el-table-column prop="payMoney" label="实付金额" width="50">
        </el-table-column>
        <el-table-column prop="totalDay" label="天数总计" width="50">
        </el-table-column>
        <el-table-column prop="buyNum" label="购卡数量" width="50">
        </el-table-column>
        <el-table-column prop="day" label="单张天数" width="50">
        </el-table-column>
        <el-table-column prop="singleRent" label="单张价格" width="50">
        </el-table-column>
        <el-table-column
          prop="totalActivityPlusMoney"
          label="活动金额"
          width="50"
        >
        </el-table-column>
        <el-table-column prop="totalGiveDay" label="赠送天数" width="50">
        </el-table-column>
        <el-table-column prop="insuranceMoney" label="保险金额" width="50">
        </el-table-column>
        <el-table-column prop="remainingDay" label="剩余天数" width="50">
          <template slot-scope="{ row }">
            <span :class="[{'bkwidth':(row.remainingDay || +row.remainingDay===0)},{'bkgreen':+row.remainingDay !== 0},{'bkred': +row.remainingDay === 0}]">
              {{ row.remainingDay}}
          </span>
          </template>
        </el-table-column>
        <el-table-column prop="cashPledgeStatus" label="押金状态" width="50">
          <template slot-scope="{ row }">
            {{ $changeObj(CASH_PLE_STATUS)[row.cashPledgeStatus] }}
            <!-- 后端返回的是0 -->
          </template>
        </el-table-column>
        <el-table-column prop="leaseTime" label="租赁时间" width="100">
        </el-table-column>
        <el-table-column prop="expireTime" label="过期时间" width="100">
        </el-table-column>
        <el-table-column prop="returnTime" label="归还时间" width="100">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="100">
        </el-table-column>
        <el-table-column fixed="right" width="100" label="操作">
          <template slot-scope="scope">
            <!-- TO DO -->
            <!-- <el-button type="text" @click="edit(scope.row)">详情</el-button> -->
            <!-- <el-button type="text" @click="edit(scope.row)">查看轨迹</el-button> -->
            <!-- <div class="btnBox"> -->
              <el-button type="primary" size="mini" v-if="scope.row.orderType != 3" @click="viewDetail(scope.row, scope.$index)"
                >查看详情</el-button
              >
              <div>
                <el-button
                  v-if="+scope.row.status === 4 && +scope.row.orderType !== 3"
                  type="primary" size="mini"
                  @click="closeOrder(scope.row, 1)"
                  >结束订单</el-button>
              </div>
              <div>
                <el-button
                  v-if="(scope.row.status == 3 || scope.row.status == 4) && scope.row.orderType != 3"
                  type="primary" size="mini"
                  @click="changeOrder(scope.row, 2)"
                  >更换电池</el-button
                >
              </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <RentModal
      ref="rentModal"
      :type="type"
      @success='onSearch'
      :productType="productType"
      :row="row"
    ></RentModal>
  </div>
</template>

<script>
import { queryLeaseOrderList ,orderExport} from "@/api/order";
import RentModal from "./components/RentModal";
import {
  EXPIRE_TYPE,
  PAY_TYPE,
  ORDER_TYPE,
  ORDER_STATUS,
  CONFIRM_TYPE,
  CASH_PLE_STATUS
} from "@/js/constant";
import AgentTree from "@/components/AgentTree";

export default {
  data() {
    return {
      EXPIRE_TYPE,
      PAY_TYPE,
      ORDER_TYPE,
      ORDER_STATUS,
      CONFIRM_TYPE,
      CASH_PLE_STATUS,
      createTimeArr:[],
      searchForm: {
        creatTimeStart:'',
        creatTimeEnd:'',
        communicationBoardNo: "",
        phone: "", // 产品型号
        orderStatus: "", // 订单状态
        workStatus: "", // 工作状态
        bmsType: "", // BMS类型
        activityStatus: "", // 激活状态
        batteryStatus: "", // 电池状态
        positionStatus: "", //定位状态
        unitNo: [],
        startTime: "",
        endTime: "",
      },
      time: "",
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
      beginIndex: 1,
      pageSize: 10,
      page:1,
      total: 0,
      list: [],
      /**选择的设备 */
      selectList: [],
      /** 产品列表 */
      productList: [],
      // 弹出层
      dialogVisible: false,
      loading: false,
      type: 1 /* 1 结束订单 ， 2 更换电池 */,
      dialogTitle: {
        1: "结束订单",
        2: "更换电池"
      },
      /** 操作的列表 */
      productType: 1,
      row: {},

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    this.createTimeArr = [start.valueOf(), end.valueOf()]
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.searchFormExpireTimeType.blur()
        _this.$refs.searchFormDischargeMosStatus.blur()
        _this.$refs.searchFormPayType.blur()
        _this.$refs.searchFormOrderType.blur()
        _this.$refs.searchFormOrderStatus.blur()
        _this.onSearch()
      }
    };
    this.queryLeaseOrderList();
  },
  //路由守卫
  beforeRouteLeave(to, from, next) {
    //如果去的页面是orderDetail就保留，否则不保留
    from.meta.keepAlive = to.name === 'orderDetail';
    next()
  },
  methods: {
    // 导出
    async exportBtn(){
      try{
        if (this.time && this.time.length >0) {
          this.searchForm.startTime = this.time[0];
          this.searchForm.endTime = this.time[1];
        } else {
          this.searchForm.startTime = null;
          this.searchForm.endTime = null;
        }
        const params = {
          ...this.searchForm,
          beginIndex: this.page,
          pageSize: this.pageSize,
          // startTime: String(Date.parse(this.startTime)),
          // endTime: String(Date.parse(this.endTime))
        };
         const unitNo = this.searchForm.unitNo[this.searchForm.unitNo.length - 1];
        params.unitNo = unitNo;
        const res = await orderExport(params);
        if(+res.result === 1){
          this.$router.push('/leaseholdMgmt/exportTask');
        }
      }catch(e){
        console.log(e);
      }
    },
    onSearch() {
      this.beginIndex = 1;
      this.queryLeaseOrderList();
    },
    async queryLeaseOrderList() {
      if (this.time && this.time.length >0) {
        this.searchForm.startTime = this.time[0];
        this.searchForm.endTime = this.time[1];
      } else {
        this.searchForm.startTime = null;
        this.searchForm.endTime = null;
      }
      if (this.createTimeArr && this.createTimeArr.length >0) {
        this.searchForm.createTimeStart = this.createTimeArr[0];
        this.searchForm.createTimeEnd = this.createTimeArr[1];
      } else {
        this.searchForm.createTimeStart = null;
        this.searchForm.createTimeEnd = null;
      }
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        beginIndex: page,
        pageSize: this.pageSize,
      };

      const unitNo = this.searchForm.unitNo[this.searchForm.unitNo.length - 1];
      params.unitNo = unitNo;
      if (this.searchForm.leaseTime) {
        params.leaseTime = String(Date.parse(this.searchForm.leaseTime));
      }
      const res = await queryLeaseOrderList(params);
      (res, "res");
      this.list = res.ldata;
      this.total = res.mdata.total;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.queryLeaseOrderList();
    },
    /** 选择 */
    handleSelectionChange(row) {
      this.selectList = row;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /**查看详情 */
    viewDetail(row) {
      this.$router.push({
        path: "/leaseholdMgmt/orderDetail",
        query: row
        })

    },
    closeOrder(row, type) {
      this.row = row;
      this.type = type;
      this.productType = row.commodityType;
      this.$refs.rentModal.show();
    },
    changeOrder(row, type) {
      this.row = row;
      this.type = type;
      this.$refs.rentModal.show();
    }
  },
  components: {
    AgentTree,
    RentModal
  }
};
</script>

<style scoped lang="less">
.battery {
  padding-bottom: 80px;
}
// .btnBox{
//   display:flex;
//   flex-direction:column;
//   align-items:center;
//   justify-content:space-around;
// }
// .btnBox>div{
//   margin-bottom:5px;
// }
</style>
